import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`阿里云ecs部署ossfs`}</p>
    <hr></hr>
    <h2 {...{
      "id": "简介"
    }}>{`简介`}</h2>
    <p>{`ossfs能让您在Linux系统中，将对象存储OSS的存储空间（Bucket）挂载到本地文件系统中，您能够像操作本地文件一样操作OSS的对象（Object），实现数据的共享。`}</p>
    <h2 {...{
      "id": "使用限制"
    }}>{`使用限制`}</h2>
    <p>{`ossfs使用有以下限制：`}</p>
    <ul>
      <li parentName="ul">{`不支持挂载归档型Bucket。`}</li>
      <li parentName="ul">{`编辑已上传文件会导致文件被重新上传。`}</li>
      <li parentName="ul">{`元数据操作，例如list directory，因为需要远程访问OSS服务器，所以性能较差。`}</li>
      <li parentName="ul">{`重命名文件/文件夹可能会出错。若操作失败，可能会导致数据不一致。`}</li>
      <li parentName="ul">{`不适合高并发读/写的场景。`}</li>
      <li parentName="ul">{`多个客户端挂载同一个OSS Bucket时，数据一致性由您自行维护。例如，合理规划文件使用时间，避免出现多个客户端写同一个文件的情况。`}</li>
      <li parentName="ul">{`不支持hard link。`}</li>
    </ul>
    <h2 {...{
      "id": "主要功能"
    }}>{`主要功能`}</h2>
    <p>{`ossfs基于s3fs构建，具有s3fs的全部功能。其中包括：`}</p>
    <ul>
      <li parentName="ul">{`支持POSIX 文件系统的大部分功能，包括文件读写、目录、链接操作、权限、uid/gid、以及扩展属性（extended attributes）。`}</li>
      <li parentName="ul">{`使用OSS的multipart功能上传大文件。`}</li>
      <li parentName="ul">{`支持MD5校验，保证数据完整性。`}</li>
    </ul>
    <h2 {...{
      "id": "快速安装"
    }}>{`快速安装`}</h2>
    <p>{`下载安装包，以下载CentOS 7.0 (x64)版本为例：`}</p>
    <pre><code parentName="pre" {...{}}>{`wget http://gosspublic.alicdn.com/ossfs/ossfs_1.80.6_centos7.0_x86_64.rpm
`}</code></pre>
    <p>{`安装命令：`}</p>
    <pre><code parentName="pre" {...{}}>{`sudo yum localinstall ossfs_1.80.6_centos7.0_x86_64.rpm
`}</code></pre>
    <p>{`配置账号访问信息:`}</p>
    <p>{`将Bucket名称以及具有此Bucket访问权限的AccessKeyId/AccessKeySecret信息存放在/etc/passwd-ossfs文件中。注意这个文件的权限必须正确设置，建议设为640。`}</p>
    <pre><code parentName="pre" {...{}}>{`echo my-bucket:my-access-key-id:my-access-key-secret > /etc/passwd-ossfs
chmod 640 /etc/passwd-ossfs
`}</code></pre>
    <p>{`将Bucket挂载到指定目录:`}</p>
    <pre><code parentName="pre" {...{}}>{`ossfs my-bucket my-mount-point -ourl=my-oss-endpoint
`}</code></pre>
    <p>{`如果您不希望继续挂载此Bucket，您可以将其卸载。`}</p>
    <pre><code parentName="pre" {...{}}>{`fusermount -u my-mount-point
`}</code></pre>
    <p>{`如何开机自动挂载ossfs:`}</p>
    <p>{`在/etc/fstab中加入下面的命令：`}</p>
    <pre><code parentName="pre" {...{}}>{`ossfs#your_bucket_name your_mount_point fuse _netdev,url=your_url,allow_other 0 0
`}</code></pre>
    <p>{`保存/etc/fstab文件。执行mount -a命令，如果没有报错，则说明设置正常。`}</p>
    <p>{`其他常见问题:`}</p>
    <p><a parentName="p" {...{
        "href": "https://help.aliyun.com/document_detail/32197.html?spm=a2c4g.11186623.6.751.10767358W4HHPw"
      }}>{`https://help.aliyun.com/document_detail/32197.html?spm=a2c4g.11186623.6.751.10767358W4HHPw`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      